import {FuseLoadable} from 'owp/@fuse';

export const C101010Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/pages/C101010',
            component: FuseLoadable({
                loader: () => import('./C101010')
            })
        },
    ]
};
