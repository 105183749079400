import { FuseLoadable } from "owp/@fuse";

export const C103911Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/pages/C103911',
            component: FuseLoadable({
                loader: () => import('./C103911')
            })
        }
    ]
};
