import {FuseLoadable} from 'owp/@fuse';

export const C103400Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/pages/C103400',
            component: FuseLoadable({
                loader: () => import('./C103400')
            })
        }
    ]
};
