import {FuseLoadable} from 'owp/@fuse';

export const C109150Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/pages/C109150',
            component: FuseLoadable({
                loader: () => import('./C109150')
            })
        }
    ]
};
