import { FuseAnimate, FuseSearch, FuseShortcuts } from 'owp/@fuse';
import {
    Avatar,
    Button,
    Hidden,
    Icon,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { Breadcrumb, LoadingIndicator } from 'owp/components';
import * as authActions from 'owp/auth/store/actions';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { OwpSession } from "owp/wrapper";
import { mutate, query } from 'owp/api';
import { $CombinedState } from 'redux';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    separator: {
        width: 1,
        height: 64,
        backgroundColor: theme.palette.divider,
    },
});

const loadingIndicatorStyle = {
    position: 'absolute',
    bottom: 0,
    width: '100%',
};

class MainToolbar extends Component {
    state = {
        permiList : [],
        mes:'',
        scm:'',
        userMenu: null,
    };

    componentDidMount() {
        console.log(window.location.pathname.substring(7, 14))
        this.session = window.localStorage.getItem('session');
        if(this.session){
            this.session = JSON.parse(window.localStorage.getItem('session'));

        this.state.permiList = this.session['auth_permission'];

            this.state.permiList.map((data, i)=>{

                if(this.state.permiList[i]['SYSTEMNM']==='MES'){
                    this.setState({
                        mes : 'mes'
                    })
                }else if(this.state.permiList[i]['SYSTEMNM']==='SCM'){
                    this.setState({
                        scm : 'scm'
                    })
                }
            });

        }
    }

    userMenuClick = event => {
        this.setState({ userMenu: event.currentTarget });
    };

    userMenuClose = () => {
        this.setState({ userMenu: null });
    };

    moveSCMPage = () => {
        window.location.href = process.env.REACT_APP_API_URL_SCM+"/pages/P100000?" + "userid=" + OwpSession('USERID') + "&pwd=" + localStorage.getItem('testPWd') + "&ip=" + localStorage.getItem('UserIP')
    };

    render() {
        const {
            classes,
            // toggleQuickPanel,
            user,
            logout,
            openChatPanel,
            breadcrumb,
        } = this.props;
        const { userMenu } = this.state;

        return (
            <React.Fragment>

                <div className={classNames(classes.root, 'flex flex-row')}>
                    <div
                        className="flex flex-1 text-2xl"
                        style={{ alignItems: 'center' }}
                    >
                        <Icon style={{ margin: '0 22px', fontSize: '34px' }}>home</Icon>
                        {window.location.pathname.substring(7, 14) == 'C902003' ? '개인정보 수정' : (
                            <Breadcrumb items={breadcrumb} />
                        )}
                        
                    </div>

                    <div id="ver" style={{marginRight: '10px', fontSize : '12px'}}>
                        ver. {process.env.REACT_APP_VERSION}
                    </div>

                    <div id="scm" style={{ display :  this.state.scm === 'scm' ? 'block' : 'none' ,cursor:'pointer', marginRight: '10px', fontSize : '20px'}} onClick={this.moveSCMPage}>
                        SCM
                    </div>

                    <div className="flex">

                        <div className={classes.separator} />

                        <FuseAnimate delay={300}>
                            <Button
                                className="h-64"
                                onClick={this.userMenuClick}
                            >
                                {/*{user.data.photoURL ? (*/}
                                {/*    <Avatar*/}
                                {/*        className=""*/}
                                {/*        alt="user photo"*/}
                                {/*        src={user.data.photoURL}*/}
                                {/*    />*/}
                                {/*) : (*/}
                                {/*    <Avatar className="">*/}
                                {/*        {user.data.displayName[0]}*/}
                                {/*    </Avatar>*/}
                                {/*)}*/}

                                <div className="hidden md:flex flex-col ml-12 items-start">
                                    <Typography
                                        component="span"
                                        className="normal-case font-600 flex"
                                    >
                                        {user.data.displayName}
                                    </Typography>
                                    <Typography
                                        className="text-11 capitalize"
                                        color="textSecondary"
                                    >
                                        {OwpSession("USERNAME")}
                                    </Typography>
                                </div>

                                <Icon
                                    className="text-16 ml-12 hidden sm:flex"
                                    variant="action"
                                >
                                    keyboard_arrow_down
                                </Icon>
                            </Button>
                        </FuseAnimate>

                        <Popover
                            open={Boolean(userMenu)}
                            anchorEl={userMenu}
                            onClose={this.userMenuClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            classes={{
                                paper: 'py-8',
                            }}
                        >
                            {user.role === 'guest' ? (
                                <React.Fragment>
                                    <MenuItem component={Link} to="/login">
                                        <ListItemIcon>
                                            <Icon>lock</Icon>
                                        </ListItemIcon>
                                        <ListItemText
                                            className="pl-0"
                                            primary="Login"
                                        />
                                    </MenuItem>
                                    <MenuItem component={Link} to="/register">
                                        <ListItemIcon>
                                            <Icon>person_add</Icon>
                                        </ListItemIcon>
                                        <ListItemText
                                            className="pl-0"
                                            primary="Register"
                                        />
                                    </MenuItem>
                                </React.Fragment>
                            ) : (
                                    <React.Fragment>
                                        {/*<MenuItem*/}
                                        {/*    component={Link}*/}
                                        {/*    to="/pages/profile"*/}
                                        {/*    onClick={this.userMenuClose}*/}
                                        {/*>*/}
                                        {/*    <ListItemIcon>*/}
                                        {/*        <Icon>account_circle</Icon>*/}
                                        {/*    </ListItemIcon>*/}
                                        {/*    <ListItemText*/}
                                        {/*        className="pl-0"*/}
                                        {/*        primary="My Profile"*/}
                                        {/*    />*/}
                                        {/*</MenuItem>*/}
                                        {/*<MenuItem*/}
                                        {/*    component={Link}*/}
                                        {/*    to="/apps/mail"*/}
                                        {/*    onClick={this.userMenuClose}*/}
                                        {/*>*/}
                                        {/*    <ListItemIcon>*/}
                                        {/*        <Icon>mail</Icon>*/}
                                        {/*    </ListItemIcon>*/}
                                        {/*    <ListItemText*/}
                                        {/*        className="pl-0"*/}
                                        {/*        primary="Inbox"*/}
                                        {/*    />*/}
                                        {/*</MenuItem>*/}
                                        <MenuItem
                                            component={Link}
                                            to="/pages/C902003"
                                            onClick={this.userMenuClose}
                                        >
                                            <ListItemIcon>
                                                <Icon>settings</Icon>
                                            </ListItemIcon>
                                            <ListItemText
                                                className="pl-0"
                                                primary="개인정보 수정"
                                            />
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                localStorage.setItem("unload", "1")
                                                logout();
                                                this.userMenuClose();
                                            }}
                                        >
                                            <ListItemIcon>
                                                <Icon>exit_to_app</Icon>
                                            </ListItemIcon>
                                            <ListItemText
                                                className="pl-0"
                                                primary="로그아웃"
                                            />
                                        </MenuItem>
                                        {/* <MenuItem
                                        component={Link}
                                        to="/examples/owpgrid"
                                        onClick={this.userMenuClose}
                                    >
                                        <ListItemIcon>
                                            <Icon>school</Icon>
                                        </ListItemIcon>
                                        <ListItemText
                                            className="pl-0"
                                            primary="그리드"
                                        />
                                    </MenuItem>
                                    <MenuItem
                                        component={Link}
                                        to="/examples/owptabs"
                                        onClick={this.userMenuClose}
                                    >
                                        <ListItemIcon>
                                            <Icon>school</Icon>
                                        </ListItemIcon>
                                        <ListItemText
                                            className="pl-0"
                                            primary="탭"
                                        />
                                    </MenuItem>
                                    <MenuItem
                                        component={Link}
                                        to="/examples/owpmessage"
                                        onClick={this.userMenuClose}
                                    >
                                        <ListItemIcon>
                                            <Icon>school</Icon>
                                        </ListItemIcon>
                                        <ListItemText
                                            className="pl-0"
                                            primary="메세지"
                                        />
                                    </MenuItem> */}
                                        {/* <MenuItem
                                        component={Link}
                                        to="/examples/owpdrawer"
                                        onClick={this.userMenuClose}
                                    >
                                        <ListItemIcon>
                                            <Icon>school</Icon>
                                        </ListItemIcon>
                                        <ListItemText
                                            className="pl-0"
                                            primary="상세보기"
                                        />
                                    </MenuItem> */}
                                    </React.Fragment>
                                )}
                        </Popover>

                        <div className={classes.separator} />

                        {/*<FuseSearch />*/}

                        <div className={classes.separator} />

                        <FuseShortcuts />

                        <Hidden lgUp>
                            <div className={classes.separator} />

                            <IconButton
                                className="w-64 h-64"
                                onClick={openChatPanel}
                            >
                                <Icon>chat</Icon>
                            </IconButton>
                        </Hidden>

                        <div className={classes.separator} />
                        {/* <IconButton
            className="w-64 h-64"
            onClick={() => toggleQuickPanel(true)}
          >
            <Icon>format_list_bulleted</Icon>
          </IconButton> */}
                    </div>
                </div>
                <LoadingIndicator
                    style={loadingIndicatorStyle}
                    {...this.props.loadingIndicatorProps}
                />
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            // toggleQuickPanel: quickPanelActions.toggleQuickPanel,
            logout: authActions.logoutUser,
        },
        dispatch
    );
}

function mapStateToProps({ auth, owp }) {
    return {
        user: auth.user,
        breadcrumb: owp.page.breadcrumb,
        loadingIndicatorProps: {
            loading:
                owp.network.status === 'START' ||
                owp.network.status === 'LOADING',
            variant:
                owp.network.status === 'LOADING' ? 'determinate' : undefined,
            value: owp.network.value,
        },
    };
}

export default withStyles(styles, { withTheme: true })(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MainToolbar)
);
