import {FuseLoadable} from 'owp/@fuse';

export const C120076Config = {

settings: {
        layout: {
            config: {
             
            }
        }
    },
    routes  : [
        {
            path     : '/pages/C120076',
            component: FuseLoadable({
                loader: () => import('./C120076')
            })
        }
    ]
};
