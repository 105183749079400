import {FuseLoadable} from 'owp/@fuse';

export const C103083Config = {
    settings: {
        layout: {
            config: {
                navbar : {
                    display : false,
                    folded  : false,
                    position: 'left'
                },
                toolbar: {
                    display : false,
                    style   : 'fixed',
                    position: 'below'
                },
                footer : {
                    display : false,
                    style   : 'fixed',
                    position: 'below'
                },
            }
        }
    },
    routes  : [
        {
            path     : '/pages/C103083',
            component: FuseLoadable({
                loader: () => import('./C103083')
            })
        }
    ]
};
