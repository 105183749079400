//import '@babel/polyfill';
// import './license';

import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import jssExtend from 'jss-extend';
import store, { persistor } from 'owp/store';
import {
    setDefaultSettings,
    setNavbarWidth,
    setNavigation
} from 'owp/store/actions';
import 'owp/styles/index.css';
import 'owp/wrapper/OwpCommon';

import React from 'react';
import ReactDOM from 'react-dom';
import JssProvider from 'react-jss/lib/JssProvider';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
//import './fake-db/fake-db';
import { query } from 'owp/api';

import { OwpSession } from 'owp/wrapper';
import { isMobile } from 'react-device-detect';
import { reactLocalStorage } from 'reactjs-localstorage';

import 'styles/custom.css';
import 'styles/index.css';
import 'typeface-muli';

import { owpNavigationConfig } from './@_configs/owpNavigationConfig';
import { routes } from './@_configs/owpRoutesConfig';
import owpSettingsConfig from './@_configs/owpSettingsConfig.json';
import registerServiceWorker from './registerServiceWorker';

import MainNavbarContent from './main/MainNavbarContent';
import MainNavbarHeader from './main/MainNavbarHeader';
import MainToolbar from './main/MainToolbar';
import MobileToolbar from './main/MobileToolbar';

import { FuseAuthorization, FuseLayout, FuseTheme } from 'owp/@fuse';
import { setDefaultAxiosConfig } from 'owp/api/axios';
import { Auth } from 'owp/auth';
import { AutoLogout } from 'owp/components';
import history from 'owp/history';

const jss = create({
    ...jssPreset(),
    plugins: [...jssPreset().plugins, jssExtend()],
});

jss.options.insertionPoint = document.getElementById('jss-insertion-point');
const generateClassName = createGenerateClassName();

setDefaultAxiosConfig();

localStorage.setItem('OWPDESK', 'true');
try {
    window.pingHost();
} catch (error) {
    localStorage.setItem('OWPDESK', 'false');
}

window.addEventListener('unload', (e) => {
    e.preventDefault();
    const UserId = localStorage.getItem('UserID');

    if (!!UserId && localStorage.getItem('OWPDESK') === 'false') {
        query({
            url: `/createUserLog/${OwpSession('USERSEQ')}/${OwpSession(
                'LOGSEG'
            )}/${localStorage.getItem('UserIP')}/창닫기`,
        });
    }
});

class App extends React.Component {
    async componentDidMount() {
        try {
            document.title = process.env.REACT_APP_DOCUMENT_TITLE;

            store.dispatch(setDefaultSettings({ theme: owpSettingsConfig.theme }));
            store.dispatch(setNavigation(owpNavigationConfig));
            store.dispatch(setNavbarWidth(280)); // nav width
        } catch (error) {
            console.error(error);
        }
    }

    allRoutes = routes;

    renderToolBar = (innerWidth) => {
        console.log('renderToolBar.innerWidth', innerWidth);

        if (innerWidth < 600 || isMobile) {
            reactLocalStorage.set('isMobile', true);
            console.log('renderToolBar = MobileToolbar');
            return <MobileToolbar />;
        } else {
            reactLocalStorage.set('isMobile', false);
            console.log('renderToolBar = MainToolbar');
            return <MainToolbar />;
        }
    };

    render() {
        console.log('window.outerWidth', window.outerWidth);
        console.log('window.innerWidth', window.innerWidth);

        const innerWidth = window.innerWidth;

        return (
            <JssProvider jss={jss} generateClassName={generateClassName}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Auth>
                            <Router history={history}>
                                <FuseAuthorization routes={this.allRoutes}>
                                    <AutoLogout />
                                    <FuseTheme>
                                        <FuseLayout
                                            routes={this.allRoutes}
                                            toolbar={this.renderToolBar(innerWidth)}
                                            navbarHeader={<MainNavbarHeader />}
                                            navbarHeaderStyle={{ background: '#fff' }}
                                            navbarContent={<MainNavbarContent />}
                                            // footer={<MainFooter />}
                                            // rightSidePanel={
                                            //     <React.Fragment>
                                            //         {/* <ChatPanel/> */}
                                            //         <QuickPanel />
                                            //     </React.Fragment>
                                            // }
                                            // contentWrapper={<SettingsPanel />}
                                        />
                                    </FuseTheme>
                                </FuseAuthorization>
                            </Router>
                        </Auth>
                    </PersistGate>
                </Provider>
            </JssProvider>
        );
    }
}
ReactDOM.render(<App />, document.getElementById('root'));

registerServiceWorker();
