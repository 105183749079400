import {FuseLoadable} from 'owp/@fuse';

export const C104093Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/pages/C104093',
            component: FuseLoadable({
                loader: () => import('./C104093')
            })
        },
    ]
};
