import {FuseLoadable} from 'owp/@fuse';

export const C107510Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/pages/C107510',
            component: FuseLoadable({
                loader: () => import('./C107510')
            })
        }
    ]
};
