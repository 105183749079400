import { FuseAnimate } from 'owp/@fuse';
import { Card, CardContent, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import LoginForm from './LoginForm';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

const styles = theme => {
    return {
        root: {
            background:
                "url('/assets/images/backgrounds/dark-material-bg.jpg') no-repeat",
            backgroundSize: 'cover',
        },
        intro: {
            color: '#ffffff',
        },
        card: {
            width: '100%',
            maxWidth: 400,
            // background: theme.palette.primary.dark,
        },
    };
};

class Login extends Component {


    // handleTabChange = (event, value) => {
    //     this.setState({ tabValue: value });
    // };

    render() {
        const { classes, logo, title, subtitle } = this.props;
        // const { tabValue } = this.state;

        return (
            <div
                className={classNames(
                    classes.root,
                    'flex flex-col flex-1 flex-no-shrink p-24 md:flex-row md:p-0'
                )}
            >
                <div
                    className={classNames(
                        classes.intro,
                        'flex flex-col flex-no-grow p-16 text-center md:p-128 md:items-start md:flex-no-shrink md:flex-1 md:text-left'
                    )}
                >
                    <FuseAnimate animation="transition.expandIn">
                        <img className="w-360 mb-16" src='/assets/images/logos/soya_login.png' alt="logo" />
                    </FuseAnimate>

                    <FuseAnimate animation="transition.slideUpIn" delay={300}>
                        <Typography
                            variant="h3"
                            color="inherit"
                            className="font-light"
                        >
                            {title}
                        </Typography>
                    </FuseAnimate>

                    {!!subtitle && (
                        <FuseAnimate delay={400}>
                            <Typography
                                variant="subtitle1"
                                color="inherit"
                                className="max-w-512 mt-16"
                            >
                                {subtitle}
                            </Typography>
                        </FuseAnimate>
                    )}
                </div>

                <FuseAnimate animation={{ translateX: [0, '100%'] }}>
                    <Card
                        className={classNames(
                            classes.card,
                            'mx-auto m-16 md:m-0'
                        )}
                    >
                        <CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 ">
                            <Typography
                                variant="h6"
                                className="text-center md:w-full mb-48"
                            >
                                로그인
                            </Typography>

                            <LoginForm />

                        </CardContent>
                    </Card>
                </FuseAnimate>
            </div>
        );
    }
}

Login.propTypes = {
    logo: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

Login.defaultProps = {
    logo: process.env.REACT_APP_LOGO_URL,
    title: process.env.REACT_APP_LOGO_NAME,
};

export default withStyles(styles, { withTheme: true })(withRouter(Login));
