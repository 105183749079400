import { FuseLoadable } from 'owp/@fuse';

export const GuidePage_1Config = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: '/pages/GuidePage_1',
            component: FuseLoadable({
                loader: () => import('./GuidePage_1'),
            }),
        },
    ],
};
