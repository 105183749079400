import {FuseLoadable} from 'owp/@fuse';

export const C107080Config = {
    settings: {
        layout: {
            config: {
                
            }
        }
    },
    routes  : [
        {
            path     : '/pages/C107080',
            component: FuseLoadable({
                loader: () => import('./C107080')
            })
        }
    ]
};
