import {FuseLoadable} from 'owp/@fuse';

export const C108010Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/pages/C108010',
            component: FuseLoadable({
                loader: () => import('./C108010')
            })
        }
    ]
};
