import {FuseLoadable} from 'owp/@fuse';

export const C105032Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/pages/C105032',
            component: FuseLoadable({
                loader: () => import('./C105032')
            })
        }
    ]
};
