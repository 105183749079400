import {FuseLoadable} from 'owp/@fuse';

export const C120082Config = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true
                },
                leftSidePanel: {
                    display: true
                },
            }
        }
    },
    routes  : [
        {
            path     : '/pages/C120082',
            component: FuseLoadable({
                loader: () => import('./C120082')
            })
        }
    ]
};
