import {FuseLoadable} from 'owp/@fuse';

export const C105031Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/pages/C105031',
            component: FuseLoadable({
                loader: () => import('./C105031')
            })
        }
    ]
};
