import {FuseLoadable} from 'owp/@fuse';

export const C120077Config = {

settings: {
        layout: {
            config: {
            }
        }
    },
    routes  : [
        {
            path     : '/pages/C120077',
            component: FuseLoadable({
                loader: () => import('./C120077')
            })
        }
    ]
};
