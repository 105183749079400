import {FuseLoadable} from 'owp/@fuse';

export const C102031Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/pages/C102031',
            component: FuseLoadable({
                loader: () => import('./C102031')
            })
        }
    ]
};
