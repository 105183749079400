import {FuseLoadable} from 'owp/@fuse';

export const C110010Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/pages/C110010',
            component: FuseLoadable({
                loader: () => import('./C110010')
            })
        }
    ]
};
