import {FuseLoadable} from 'owp/@fuse';

export const C102060Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/pages/C102060',
            component: FuseLoadable({
                loader: () => import('./C102060')
            })
        }
    ]
};
