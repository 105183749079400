import { FuseLoadable } from 'owp/@fuse';

export const C120080Config = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true
                },
                leftSidePanel: {
                    display: true
                },
            }
        }
    },
    routes: [
        {
            path: '/pages/C120080',
            component: FuseLoadable({
                loader: () => import('./C120080')
            })
        }
    ]
};
