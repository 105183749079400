import { FuseLoadable } from 'owp/@fuse';

export const C104060Config = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: '/pages/C104060',
            component: FuseLoadable({
                loader: () => import('./C104060'),
            }),
        },
    ],
};
