import { FuseLoadable } from 'owp/@fuse';

export const GuidePage_3Config = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: '/pages/GuidePage_3',
            component: FuseLoadable({
                loader: () => import('./GuidePage_3'),
            }),
        },
    ],
};
