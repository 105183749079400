import { AppBar, Avatar, Hidden, withStyles, } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

const styles = theme => ({
  avatar: {
    width: "155px",
    height: "44px",
    position: 'absolute',
    top: "10px",
    //padding: 5,
    //background: '#d73c2a',
    boxSizing: 'content-box',
    borderRadius: 0,
    '& > img': {
      borderRadius: 0,
    },
  }
});

function MainNavbarLogo({ classes }) {
  return (
    <AppBar
      style={{ background: '#fff' }}
      position="static"
      color="primary"
      elevation={0}
      className="user relative flex flex-col items-center justify-center pt-24 pb-16 mb-32 z-0"
    >

      <Avatar
        className={classNames(classes.avatar, 'avatar')}
        alt={process.env.REACT_APP_LOGO_NAME}
        src='assets/images/logos/soya.png'
      />

    </AppBar>
  );
}

export default withStyles(styles, { withTheme: true })(MainNavbarLogo);
