import { FuseAnimate, FuseSearch, FuseShortcuts } from 'owp/@fuse';
import {
    Avatar,
    Button,
    Hidden,
    Icon,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { Breadcrumb, LoadingIndicator } from 'owp/components';
import * as authActions from 'owp/auth/store/actions';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {OwpButton} from "owp/wrapper";

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    separator: {
        width: 1,
        height: 64,
        backgroundColor: theme.palette.divider,
    },
});

const loadingIndicatorStyle = {
    position: 'absolute',
    bottom: 0,
    width: '100%',
};

class MainToolbar extends Component {
    state = {
        userMenu: null,
    };

    userMenuClick = event => {
        this.setState({ userMenu: event.currentTarget });
    };

    userMenuClose = () => {
        this.setState({ userMenu: null });
    };

    render() {
        const {
            classes,
            // toggleQuickPanel,
            user,
            logout,
            openChatPanel,
            breadcrumb,
        } = this.props;
        const { userMenu } = this.state;

        return (
            <React.Fragment>
                <div className={classNames(classes.root, 'flex flex-row')}>
                    <div
                        className="flex flex-1 text-18 pl-6"
                        style={{ alignItems: 'center' }}
                    >
                        <Breadcrumb items={breadcrumb} />
                    </div>

                    <div className="flex">


                        <Button variant="outlined" className="mr-6">
                            QR SCAN
                            {/*<Icon*/}
                            {/*    className="text-24 ml-6 sm:flex"*/}
                            {/*    variant="action"*/}
                            {/*>*/}
                            {/*    settings_overscan*/}
                            {/*</Icon>*/}
                        </Button>

                        {/*<div className={classes.separator} />*/}

                        {/*<FuseAnimate delay={300}>*/}
                        {/*    <Button*/}
                        {/*        className="h-64"*/}
                        {/*        onClick={this.userMenuClick}*/}
                        {/*    >*/}

                        {/*        <div className="hidden md:flex flex-col ml-12 items-start">*/}
                        {/*            <Typography*/}
                        {/*                component="span"*/}
                        {/*                className="normal-case font-600 flex"*/}
                        {/*            >*/}
                        {/*                {user.data.displayName}*/}
                        {/*            </Typography>*/}
                        {/*            <Typography*/}
                        {/*                className="text-11 capitalize"*/}
                        {/*                color="textSecondary"*/}
                        {/*            >*/}
                        {/*                {user.role}*/}
                        {/*            </Typography>*/}
                        {/*        </div>*/}

                        {/*        <Icon*/}
                        {/*            className="text-24 ml-6 sm:flex"*/}
                        {/*            variant="action"*/}
                        {/*        >*/}
                        {/*            keyboard_arrow_down*/}
                        {/*        </Icon>*/}
                        {/*    </Button>*/}
                        {/*</FuseAnimate>*/}

                        <Popover
                            open={Boolean(userMenu)}
                            anchorEl={userMenu}
                            onClose={this.userMenuClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            classes={{
                                paper: 'py-8',
                            }}
                        >
                            {user.role === 'guest' ? (
                                <React.Fragment>
                                    <MenuItem component={Link} to="/login">
                                        <ListItemIcon>
                                            <Icon>lock</Icon>
                                        </ListItemIcon>
                                        <ListItemText
                                            className="pl-0"
                                            primary="Login"
                                        />
                                    </MenuItem>
                                    <MenuItem component={Link} to="/register">
                                        <ListItemIcon>
                                            <Icon>person_add</Icon>
                                        </ListItemIcon>
                                        <ListItemText
                                            className="pl-0"
                                            primary="Register"
                                        />
                                    </MenuItem>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <MenuItem
                                        onClick={() => {
                                            logout();
                                            this.userMenuClose();
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Icon>exit_to_app</Icon>
                                        </ListItemIcon>
                                        <ListItemText
                                            className="pl-0"
                                            primary="Logout"
                                        />
                                    </MenuItem>
                                </React.Fragment>
                            )}
                        </Popover>

                    </div>
                </div>
                <LoadingIndicator
                    style={loadingIndicatorStyle}
                    {...this.props.loadingIndicatorProps}
                />
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            // toggleQuickPanel: quickPanelActions.toggleQuickPanel,
            logout: authActions.logoutUser,
        },
        dispatch
    );
}

function mapStateToProps({ auth, owp }) {
    return {
        user: auth.user,
        breadcrumb: owp.page.breadcrumb,
        loadingIndicatorProps: {
            loading:
                owp.network.status === 'START' ||
                owp.network.status === 'LOADING',
            variant:
                owp.network.status === 'LOADING' ? 'determinate' : undefined,
            value: owp.network.value,
        },
    };
}

export default withStyles(styles, { withTheme: true })(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MainToolbar)
);
