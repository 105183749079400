import {FuseLoadable} from 'owp/@fuse';

export const C109050Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/pages/C109050',
            component: FuseLoadable({
                loader: () => import('./C109050')
            })
        }
    ]
};
