import { FuseLayouts, FuseNavigation } from 'owp/@fuse';
import {
  AppBar,
  Avatar,
  Hidden,
  Typography,
  withStyles,
} from '@material-ui/core'; 
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { OwpNavSearch } from "owp/wrapper";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import MainNavbarLogo from "@_configs/MainNavbarLogo";

const styles = theme => ({
  root: {
    '& .user': {
      '& .username, & .email': {
        transition: theme.transitions.create('opacity', {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.easeInOut,
        }),
      },
    },
  },

  paper    : {
    paddingBottom: 30
  },
  subHeader: {
    backgroundColor: theme.palette.background.paper
  },
  appBar   : {
    top   : 'auto',
    bottom: 0,

  },
  toolbar  : {
    alignItems    : 'center',
    justifyContent: 'space-between',
    minHeight: 50,
    height: 50,
    paddingLeft: 1,
    paddingRight: 1
  }
});

function MainNavbar({ classes, navigation, layoutStyle, user }) {
  function UserHeader() {
    return (
        <MainNavbarLogo />
    );
  }

  const navigationLayout = FuseLayouts[layoutStyle].type;
  return (
      <div className={classes.root}>
        {navigationLayout === 'vertical' ? (
            <React.Fragment>

              <Paper square className={classes.paper}>

                <FuseNavigation navigation={navigation} layout={navigationLayout}/>
              </Paper>
              <AppBar position="sticky" className={classes.appBar} color="inherit">
                <Toolbar className={classes.toolbar}>

                  <OwpNavSearch/>
                </Toolbar>
              </AppBar>

            </React.Fragment>
        ) : (
            <React.Fragment>
              <Hidden lgUp>
                <UserHeader/>
              </Hidden>
              <FuseNavigation navigation={navigation} layout={navigationLayout}/>
            </React.Fragment>
        )}
      </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps({ fuse, auth }) {
  return {
    navigation: fuse.navigation,
    layoutStyle: fuse.settings.current.layout.style,
    user: auth.user,
  };
}

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(MainNavbar)
  )
);
