import {FuseLoadable} from 'owp/@fuse';

export const C107550Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/pages/C107550',
            component: FuseLoadable({
                loader: () => import('./C107550')
            })
        }
    ]
};
