import {FuseLoadable} from 'owp/@fuse';

export const C120062Config = {
    settings: {
        layout: {
            config: {
                
            }
        }
    },
    routes  : [
        {
            path     : '/pages/C120062',
            component: FuseLoadable({
                loader: () => import('./C120062')
            })
        }
    ]
};
