import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import MainNavbarLogo from "../@_configs/MainNavbarLogo";



const styles = theme => ({
    root: {
        display: 'flex flex-col',
        alignItems: 'center',
        '& .logo-text, & .react-badge': {
            transition: theme.transitions.create('opacity', {
                duration: theme.transitions.duration.shortest,
                easing: theme.transitions.easing.easeInOut,
            }),
        },
    },
    logo: {},
    logoIcon: {
        width: 24,
        height: 24,
        transition: theme.transitions.create(['width', 'height'], {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
    reactBadge: {
        backgroundColor: 'rgba(0,0,0,0.6)',
        color: '#61dafb',
    },
});

function MainNavbarHeader({ classes, logoUrl, logoName }) {
    return (
        <div className={classes.root}>
            <MainNavbarLogo/>

        </div>
    );
}

MainNavbarHeader.propTypes = {
    classes: PropTypes.object,
    logoUrl: PropTypes.string,
    logoName: PropTypes.string,
};

MainNavbarHeader.defaultProps = {
    logoUrl: process.env.REACT_APP_LOGO_URL,
    logoName: process.env.REACT_APP_LOGO_NAME,
};

export default withStyles(styles, { withTheme: true })(
    withRouter(MainNavbarHeader)
);
