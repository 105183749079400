import {FuseLoadable} from 'owp/@fuse';

export const C998000Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/pages/C998000',
            component: FuseLoadable({
                loader: () => import('./C998000')
            })
        }
    ]
};
