export const owpNavigationConfig = [

// 원래 닫았음

{
    id: 'applications2',
    title: 'HKCB Menu',
    type: 'group',
    icon: 'apps',
    children: [
        {
            id: 'C100000',
            title: 'Dashboard',
            type: 'collapse',
            icon: 'filter_none',
            children: [
                {
                    id: 'C101010',
                    title: '종합상황실(Owner)',
                    type: 'item',
                    icon: 'desktop_mac',
                    url: '/pages/C101010'
                },
                {
                    id: 'C101020',
                    title: '생산실적 현황',
                    type: 'item',
                    icon: 'desktop_mac',
                    url: '/pages/C101020'
                },
                {
                    id: 'C101030',
                    title: '공정실시간 현황(3D)',
                    type: 'item',
                    icon: 'devices_other',
                    url: '/pages/C101030'
                },
                {
                    id: 'C101040',
                    title: '장비가동현황',
                    type: 'item',
                    icon: 'devices',
                    url: '/pages/C101040'
                },
            ]
        },

        {
            id: 'C200000',
            title: '생산관리',
            type: 'collapse',
            icon: 'filter_tilt_shift',
            children: [
                {
                    id: 'C102010',
                    title: '수주반영',
                    type: 'item',
                    url: '/pages/C102010',
                },
                {
                    id: 'C102020',
                    title: 'Work Day 관리',
                    type: 'item',
                    url: '/pages/C102020',
                },
                {
                    id: 'C102030',
                    title: '생산계획',
                    type: 'collapse',
                    url: '/pages/C201010',
                    children: [

                        {
                            id: 'C102031',
                            title: '설비별 생산계획 등록',
                            type: 'item',
                            url: '/pages/C102031',
                        },

                        {
                            id: 'C102032',
                            title: '품목별 생산계획 조회',
                            type: 'item',
                            url: '/pages/C102032',
                        },

                        {
                            id: 'C102033',
                            title: '설비별 재공품/금형설정',
                            type: 'item',
                            url: '/pages/C102033',
                        },
                        {
                            id: 'C102034',
                            title: '재공품기준 할당 비율 설정',
                            type: 'item',
                            url: '/pages/C102034',
                        },
                        {
                            id: 'C102035',
                            title: '완성품기준 재고기간 설정',
                            type: 'item',
                            url: '/pages/C102035',
                        },
                        {
                            id: 'C102036',
                            title: '공정별 휴일/시간 설정',
                            type: 'item',
                            url: '/pages/C102036',
                        },
                        {
                            id: 'C102039',
                            title: '설비별 생산계획 조회',
                            type: 'item',
                            url: '/pages/C102039',
                        },
                    ]
                },
                {
                    id: 'C102040',
                    title: '긴급작업지시',
                    type: 'item',
                    url: '/pages/C102040',
                },
                {
                    id: 'C102050',
                    title: 'Rework 작업지시',
                    type: 'item',
                    url: '/pages/C102050',
                }, 
                {
                    id: 'C102060',
                    title: '생산현황',
                    type: 'item',
                    url: '/pages/C102060',
                },
                {
                    id: 'C102070',
                    title: '생산실적',
                    type: 'item',
                    url: '/pages/C102070',
                },

                {
                    id: 'C102080',
                    title: '생산분석',
                    type: 'item',
                    url: '/pages/C102080',
                },
                {
                    id: 'C102090',
                    title: '투입공수관리',
                    type: 'item',
                    url: '/pages/C102090',
                }, 
                {
                    id: 'C102100',
                    title: '작업지시 등록',
                    type: 'item',
                    url: '/pages/C102100',
                }, 
            ],
        },

        {
            id: 'C300000',
            title: '공정관리',
            type: 'collapse',
            icon: 'filter_none',
            children: [
                {
                    id: 'C103010',
                    title: '작업배정',
                    type: 'item',
                    url: '/pages/C103010',
                },
                {
                    id: 'C103020',
                    title: '스탬핑작업일보',
                    type: 'item',
                    url: '/pages/C103020',
                },
                {
                    id: 'C103030',
                    title: '열처리작업일보',
                    type: 'item',
                    url: '/pages/C103030',
                },
                {
                    id: 'C103040',
                    title: '행잉작업일보',
                    type: 'item',
                    url: '/pages/C103040',
                },
                {
                    id: 'C103050',
                    title: '사출작업일보',
                    type: 'item',
                    url: '/pages/C103050',
                },
                {
                    id: 'C103140',
                    title: '사출 건조투입',
                    type: 'item',
                    url: '/pages/C103140',
                },

                {
                    id: 'C103060',
                    title: '웰딩(용접)작업일보',
                    type: 'item',
                    url: '/pages/C103060',
                },
                {
                    id: 'C103070',
                    title: '조립작업일보',
                    type: 'item',
                    url: '/pages/C103070',
                },
                {
                    id: 'C103080',
                    title: '검사작업일보',
                    type: 'item',
                    url: '/pages/C103080',
                },
                {
                    id: 'C103090',
                    title: '작업내역관리',
                    type: 'item',
                    url: '/pages/C103090',
                },
                {
                    id: 'C103100',
                    title: '작업표준정보',
                    type: 'item',
                    url: '/pages/C103100',
                },
                {
                    id: 'C103110',
                    title: '내수 출하관리',
                    type: 'item',
                    url: '/pages/C103110',
                },
                {
                    id: 'C103120',
                    title: '수출 출하관리',
                    type: 'item',
                    url: '/pages/C103120',
                },
                {
                    id: 'C103130',
                    title: '수출바코드 관리',
                    type: 'item',
                    url: '/pages/C103130',
                },
                {
                    id: 'C103210',
                    title: '비가동작업지시',
                    type: 'item',
                    url: '/pages/C103210',
                },
            ]
        },

        {
            id: 'C104000',
            title: '품질분석',
            type: 'collapse',
            icon: 'dns',
            children: [
                {
                    id: 'C104010',
                    title: '품질검사내역',
                    type: 'item',
                    url: '/pages/C104010',
                },
                {
                    id: 'C104020',
                    title: '제조이력추적(LOT)',
                    type: 'item',
                    url: '/pages/C104020',
                },
                {
                    id: 'C104030',
                    title: '제품불량내역',
                    type: 'item',
                    url: '/pages/C104030',
                },
                {
                    id: 'C104040',
                    title: '불량원인분석',
                    type: 'item',
                    url: '/pages/C104040',
                },
                {
                    id: 'C104050',
                    title: '관리도',
                    type: 'item',
                    url: '/pages/C104050',
                },
                {
                    id: 'C104085',
                    title: 'LOT 추적',
                    type: 'item',
                    url: '/pages/C104085',
                },
            ]
        },

        {
            id: 'C105000',
            title: 'MRP',
            type: 'collapse',
            icon: 'horizontal_split',
            children: [
                {
                    id: 'C105010',
                    title: '창고관리',
                    type: 'collapse',
                    children: [

                        {
                            id: 'C105011',
                            title: '자재 입/출고 관리',
                            type: 'item',
                            url: '/pages/C105011',
                        },
                        {
                            id: 'C105012',
                            title: '완제품 입/출고 관리',
                            type: 'item',
                            url: '/pages/C105012',
                        },
                        {
                            id: 'C105013',
                            title: '완제품 재고 현황',
                            type: 'item',
                            url: '/pages/C105013',
                        },

                    ]

                },
                {
                    id: 'C105020',
                    title: '구매관리',
                    type: 'collapse',
                    children: [

                        {
                            id: 'C105021',
                            title: '구매발주',
                            type: 'item',
                            url: '/pages/C105021',
                        },
                        {
                            id: 'C105022',
                            title: '구매입고',
                            type: 'item',
                            url: '/pages/C105022',
                        },
                        {
                            id: 'C105023',
                            title: '구매입고현황',
                            type: 'item',
                            url: '/pages/C105023',
                        },
                    ]

                },
                {
                    id: 'C105030',
                    title: '공정관리',
                    type: 'collapse',
                    children: [
                        {
                            id: 'C105031',
                            title: '공정입고',
                            type: 'item',
                            url: '/pages/C105031',
                        },
                        {
                            id: 'C105032',
                            title: '공정출고',
                            type: 'item',
                            url: '/pages/C105032',
                        },
                        {
                            id: 'C105033',
                            title: '공정입/출고 현황',
                            type: 'item',
                            url: '/pages/C105033',
                        },
                        {
                            id: 'C105034',
                            title: '재공품 현황',
                            type: 'item',
                            url: '/pages/C105034',
                        },

                    ]
                },
                {
                    id: 'C105040',
                    title: '출하관리',
                    type: 'collapse',
                    children: [
                        {
                            id: 'C105041',
                            title: '내수출하',
                            type: 'item',
                            url: '/pages/C105041',
                        },
                        {
                            id: 'C105042',
                            title: '해외출하',
                            type: 'item',
                            url: '/pages/C105042',
                        },

                    ]
                },
                {
                    id: 'C105050',
                    title: '자재 소요관리',
                    type: 'item',
                    url: '/pages/C105050',
                },
             
                {
                    id: 'C105060',
                    title: '실사재고관리',
                    type: 'item',
                    url: '/pages/C105060',
                },
               
            ]
        },


         {
            id: 'C106000',
            title: 'BOM/BOP',
            type: 'collapse',
            icon: 'important_devices',
            children: [
                {
                    id: 'C106010',
                    title: '공정별 재공품관리',
                    type: 'item',
                    url: '/pages/C106010',
                },
                {
                    id: 'C106020',
                    title: '제품별 Routing',
                    type: 'item',
                    url: '/pages/C106020',
                },


            ]
        },

         {
            id: '107000',
            title: 'CRM',
            type: 'collapse',
            icon: 'important_devices',
            children: [
                {
                    id: 'C107010',
                    title: '고객사 관리',
                    type: 'item',
                    url: '/pages/C107010',
                },
                {
                    id: 'C107020',
                    title: '고객사별 제품 관리',
                    type: 'item',
                    url: '/pages/C107020',
                },
                {
                    id: 'C107030',
                    title: '제품별 고객사 관리',
                    type: 'item',
                    url: '/pages/C107030',
                },
                {
                    id: 'C107040',
                    title: '수주관리',
                    type: 'item',
                    url: '/pages/C107040',
                },
                {
                    id: 'C107050',
                    title: '수출 인보이스 관리',
                    type: 'item',
                    url: '/pages/C107050',
                },
                {
                    id: 'C107060',
                    title: 'PACKING LIST 등록',
                    type: 'item',
                    url: '/pages/C107070'
                },
                {
                    id: 'C107070',
                    title: 'PACKING LIST 등록',
                    type: 'item',
                    url: '/pages/C107070'
                }
            ]
        },

    ]
},

 
{
    id: 'C108000',
    title: '설비관리',
    type: 'collapse',
    icon: 'keyboard',
    children: [
        {
            id: 'C108010',
            title: '보전관리',
            type: 'item',
            url: '/pages/C108010',
        },
        {
            id: 'C108020',
            title: '설비이력',
            type: 'item',
            url: '/pages/C108020',
        },
        {
            id: 'C108030',
            title: '예비품 관리',
            type: 'item',
            url: '/pages/C108030',
        },
        {
            id: 'C108040',
            title: '공구관리',
            type: 'item',
            url: '/pages/C108040',
        },
        {
            id: 'C108050',
            title: '금형관리',
            type: 'item',
            url: '/pages/C108050',
        },
        {
            id: 'C108060',
            title: '설비효율(OEE)',
            type: 'item',
            url: '/pages/C108060',
        },
    ]
},
 
{
    id: 'C109000',
    title: '기준정보',
    type: 'collapse',
    icon: 'keyboard',
    children: [
        {
            id: 'C109010',
            title: '공정관리',
            type: 'item',
            url: '/pages/C109010',
        },
        {
            id: 'C109020',
            title: '설비관리',
            type: 'item',
            url: '/pages/C109020',
        },
        {
            id: 'C109030',
            title: '고객사관리',
            type: 'item',
            url: '/pages/C109030',
        },
        {
            id: 'C109040',
            title: '고객사별 제품관리',
            type: 'item',
            url: '/pages/C109040',
        },
        {
            id: 'C109050',
            title: '제품별 고객사관리',
            type: 'item',
            url: '/pages/C109050',
        },
         {
            id: 'C109070',
            title: '협력사관리',
            type: 'item',
            url: '/pages/C109070',
        },
        {
            id: 'C109080',
            title: '협력사별 자재관리',
            type: 'item',
            url: '/pages/C109080',
        },
        {
            id: 'C109090',
            title: '작업표준 정보',
            type: 'item',
            url: '/pages/C109090',
        },
    ]
},
 {
    id: 'C110000',
    title: '데이터 수집 관리',
    type: 'collapse',
    icon: 'keyboard',
    children: [
        {
            id: 'C110010',
            title: '공정데이터 수집 내역',
            type: 'item',
            url: '/pages/C110010',
        },
        {
            id: 'C110020',
            title: '품질정보',
            type: 'item',
            url: '/pages/C110020',
        },
    ]
}, 
{
    id: 'C990000',
    title: '시스템 관리',
    type: 'collapse',
    icon: 'keyboard',
    children: [
        {
            id: 'C991000',
            title: '사용자 관리',
            type: 'item',
            url: '/pages/C991000',
        },
        {
            id: 'C992000',
            title: '권한 관리',
            type: 'item',
            url: '/pages/C992000',
        },
        {
            id: 'C993000',
            title: '공통코드 관리',
            type: 'item',
            url: '/pages/C993000',
        },
        {
            id: 'C994000',
            title: '은행계좌 관리',
            type: 'item',
            url: '/pages/C994000',
        },
        {
            id: 'C995000',
            title: '국가코드 관리',
            type: 'item',
            url: '/pages/C995000',
        },
        {
            id: 'C996000',
            title: '금형관리',
            type: 'item',
            url: '/pages/C996000',
        },
        {
            id: 'C999000',
            title: '공지사항 관리',
            type: 'item',
            url: '/pages/C999000',
        },
    ]
},
];

