import { FuseLoadable } from 'owp/@fuse';

export const C104070Config = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: '/pages/C104070',
            component: FuseLoadable({
                loader: () => import('./C104070'),
            }),
        },
    ],
};
