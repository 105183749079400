import {FuseLoadable} from 'owp/@fuse';

export const C120070Config = {

settings: {
        layout: {
            config: {
             
            }
        }
    },
    routes  : [
        {
            path     : '/pages/C120070',
            component: FuseLoadable({
                loader: () => import('./C120070')
            })
        }
    ]
};
