import { TextFieldFormsy } from 'owp/@fuse';
import { Button, Icon, InputAdornment, withStyles } from '@material-ui/core';
import * as Actions from 'owp/auth/store/actions';
import Formsy from 'formsy-react';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import jwtService from 'owp/jwtService';
import store from 'owp/store';
import { setCommonCodeData } from 'owp/auth/store/actions/common.actions';
import moment from 'moment'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import {
    OwpSearchSelectField,
    OwpButton,
    OwpMessage,
    OwpDialog,
    OwpSelectField,
    LoginNumberPad,
} from 'owp/wrapper';
import { mutate, query } from 'owp/api';
import axios from 'axios';

const styles = (theme) => ({
    root: {
        width: '100%',
    },
});
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

let radioValue = '공정';
let data = '';
let nowIP = '';

class LoginForm extends Component {
    state = {
        canSubmit: false,
        keyFlag: 0,
        /** keypad 관련 */
        showNumberPad: false,
        showNumberPad2: false,
        nowNumberPadInputSelector: null,
        keyboardInput: '',
        nowUseKeyboard: false,
        numberPadLocation: {
            left: 0,
            top: 0,
            position: 'fixed',
        },
        permiList: [],
        mes: '',
    };

    constructor(props) {
        super(props);
        this.owpDialog = React.createRef();
        this.state = {
            idItem: [],
        };
    }

    form = React.createRef();

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    onSubmit = (model) => {
        if (
            this.state.UserID === undefined ||
            this.state.UserID === '' ||
            this.state.pwd === undefined ||
            this.state.pwd === ''
        ) {
            return;
        } else {
            this.props.submitLogin(model, { useMyIP: true }).then(({ type }) => {
                type === 'LOGIN_SUCCESS' && window.location.reload();
            });
        }
    };

    keyCNT = () => {
        localStorage.setItem("login time", moment().format('YYYY-MM-DD hh:mm:ss'));
        this.state.keyFlag = 0;
    };

    componentDidMount() {
        const _daialogCostNm = this.openDialog;

        localStorage.removeItem('pwchange');
        const userID = window.localStorage.getItem('UserID');
        if (userID) {
            this.setState({ UserID: userID });
        }
        this.fair_setting();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.login.error &&
            (this.props.login.error.email || this.props.login.error.password)
        ) {
            this.form.updateInputsWithError({
                ...this.props.login.error,
            });

            this.props.login.error = null;
            this.disableButton();
        }

        if (this.props.user.role !== 'guest') {
            const pathname = this.props.location.state && this.props.location.state.redirectUrl;
            // ? this.props.location.state.redirectUrl
            // : '/';

            if (pathname) {
                this.props.history.push({
                    pathname,
                });
            }
        }
        return null;
    }

    // 업체검색 팝업
    openDialog = () => {
        this.owpDialog.handleClickOpen();
    };

    // 공정명의 값을 선택 시 설비명에 데이터 항목이 생김.
    fair_setting = () => {
        query({
            url: '/listIpxCommoncode',
            params: {
                jsondata:
                    '{"IPX_COMMONCODE.DESCRIPTION" : "' +
                    radioValue +
                    '", "IPX_COMMONCODE.GROUPID" : "' +
                    'A001000' +
                    '"}',
            },
        })
            .then((res) => {
                this.setState({
                    fairItem: res.map((item) => ({
                        label: item['IPX_COMMONCODE.CODENM'],
                        value: item['IPX_COMMONCODE.CODEID'],
                    })),
                });
            })
            .catch((err) => {
                console.error(err);
            });
    };

    id_setting = () => {
        query({
            url: '/listIpxUser',
            params: {
                jsondata:
                    '{"IPX_USER.DEPTID" : "' +
                    this.state.fairInput +
                    '", "IPX_USER.FLAG" : "' +
                    'Y' +
                    '"}',
            },
        })
            .then((res) => {
                this.setState({
                    idItem: res.map((item) => ({
                        label: item['IPX_USER.USERNAME'],
                        value: item['IPX_USER.USERID'],
                    })),
                });
            })
            .catch((err) => {
                console.error(err);
            });
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value }, () => {
            this.setState({ UserID: this.state['idSelect'] });
        });
    };

    /**
     * KeyPad
     */

    changeNum = (event, type) => {
        let num;
        let idx;
        let nowUseKeyboardChk = false;

        if (type !== 'input') {
            // 가상 키보드로 입력 중
            num = this.state.keyboardInput;
            nowUseKeyboardChk = true;
            this.state.keyFlag = 1;
        } // 키보드로 입력 중
        else {
            this.state.showNumberPad = false;
            num = event.target.value;
            nowUseKeyboardChk = false;
            this.state.keyFlag = 0;
        }
        this.setState({ pwd: num });
    };

    changeNum2 = (event, type) => {
        let num2;
        let idx;
        let nowUseKeyboardChk = false;

        if (type !== 'input') {
            // 가상 키보드로 입력 중
            num2 = this.state.keyboardInput2;
            this.state.keyFlag = 1;
        } // 키보드로 입력 중
        else {
            this.state.showNumberPad2 = false;
            num2 = event.target.value;
            nowUseKeyboardChk = false;
            this.state.keyFlag = 0;
        }
        this.setState({ UserID: num2 });
    };

    showNumberPad = (event) => {
        this.setState({
            showNumberPad: true,
            showNumberPad2: false,
            nowUseKeyboard: true,
            nowNumberPadInputSelector: event.target,
            numberPadLocation: {
                left: 85,
                top: 620,
            },
        });
    };

    showNumberPad2 = (event) => {
        this.setState({
            showNumberPad: false,
            showNumberPad2: true,
            nowUseKeyboard: true,
            nowNumberPadInputSelector: event.target,
            numberPadLocation: {
                left: 85,
                top: 620,
            },
        });
    };

    closeKeyboard = () => {
        this.setState({
            showNumberPad: false,
            showNumberPad2: false,
            nowNumberPadInputSelector: null,
            nowUseKeyboard: false,
        });
    };

    keyboardInput = (value) => {
        this.setState(
            {
                keyboardInput: value,
            },
            this.changeNum
        );
    };

    keyboardInput2 = (value) => {
        this.setState(
            {
                keyboardInput2: value,
            },
            this.changeNum2
        );
    };

    selectId = () => {
        this.owpDialog.handleClose();

        this.setState({
            UserID: this.state.selId,
            fairInputID: this.state.selVal,
        });

        document.getElementById('password').focus();
    };

    render() {
        const { classes } = this.props;
        const { canSubmit } = this.state;
        const numberPadSection = this.state.showNumberPad ? (
            <LoginNumberPad
                nowUseKeyboard={this.state.nowUseKeyboard}
                numberPadLocation={this.state.numberPadLocation}
                closeKeyboard={this.closeKeyboard}
                nowNumberPadInputSelector={this.state.nowNumberPadInputSelector}
                keyboardInput={this.keyboardInput}
            />
        ) : null;
        const numberPadSection2 = this.state.showNumberPad2 ? (
            <LoginNumberPad
                nowUseKeyboard={this.state.nowUseKeyboard}
                numberPadLocation={this.state.numberPadLocation}
                closeKeyboard={this.closeKeyboard}
                nowNumberPadInputSelector={this.state.nowNumberPadInputSelector}
                keyboardInput={this.keyboardInput2}
            />
        ) : null;
        const { search } = this.props.location;

        if (search !== '') {
            this.state.UserID = search.split('&')[0].split('=')[1];
            this.state.pwd = search.split('&')[1].split('=')[1];

            if (this.props.user.role !== 'staff') {
                jwtService
                    .signInWithEmailAndPassword(this.state.UserID, this.state.pwd)
                    .then(async (user) => {
                        this.props.user.role = 'staff';
                        //store.dispatch(setNavigation(mapNav(user)));
                        //window.location.href = process.env.REACT_APP_API_URL_MES
                        store.dispatch(Actions.setUserData(user));
                        localStorage.setItem('testPWd', this.state.pwd);
                        const result = store.dispatch({
                            type: LOGIN_SUCCESS,
                        });

                        try {
                            store.dispatch(
                                setCommonCodeData(
                                    await query({
                                        url: '/listIPX_CommonCodeTotalAC',
                                    })
                                )
                            );
                        } catch (error) {
                            console.error(error);
                        }

                        window.location.reload();
                    });
            }
        }
        let left_list_body = '';

        left_list_body = this.state.idItem.map((info, i) => (
            <div
                style={{ padding: '10px', float: 'left', width: '11%', cursor: 'pointer' }}
                onClick={() => {
                    this.setState(
                        {
                            selId: info['value'],
                            selVal: info['label'],
                        },
                        () => this.selectId()
                    );
                }}
            >
                <tr>
                    <td className="t_right" style={{ padding: '10px', fontSize: '14px' }}>
                        {info['label']}
                    </td>
                </tr>
            </div>
        ));

        return (
            <React.Fragment>
                <div className={classes.root}>
                    <Formsy
                        onValidSubmit={this.onSubmit}
                        onValid={this.enableButton}
                        onInvalid={this.disableButton}
                        ref={(form) => (this.form = form)}
                        className="flex flex-col justify-center w-full"
                    >
                        {/* <div>
                            {numberPadSection}
                        </div>

                        <div>
                            {numberPadSection2}
                        </div> */}

                        <TextFieldFormsy
                            className="mb-16"
                            type="text"
                            name="email"
                            label="아이디"
                            value={this.state.UserID}
                            //onFocus={this.showNumberPad}
                            onClick={this.showNumberPad2}
                            onChange={(event) => this.changeNum2(event, 'input')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon className="text-20" color="action">
                                            email
                                        </Icon>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            //required
                            autoComplete="off"
                        />

                        <TextFieldFormsy
                            className="mb-16"
                            id="password"
                            type="password"
                            name="password"
                            label="패스워드"
                            value={this.state.pwd}
                            //onFocus={this.showNumberPad}
                            onClick={this.showNumberPad}
                            onChange={(event) => this.changeNum(event, 'input')}
                            validations={{
                                minLength: 4,
                                //matchRegexp: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{4,}$/
                            }}
                            validationErrors={{
                                minLength: '패스워드는 최소 4자 이상이어야 합니다.',
                                matchRegexp: '패스워드는 문자와 숫자의 조합이어야합니다.',
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon className="text-20" color="action">
                                            vpn_key
                                        </Icon>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            //required
                        />

                        <Button
                            onClick={this.keyCNT}
                            type="submit"
                            variant="contained"
                            color="secondary"
                            className="w-full mx-auto mt-16 normal-case"
                            aria-label="LOG IN"
                            //disabled={!canSubmit}
                            value="legacy"
                        >
                            로그인
                        </Button>
                    </Formsy>
                    {/*<div style={{display: 'none'}}>*/}
                    <div style={{ display: 'none' }}>
                        <iframe id="ifr_mes" src="" />
                    </div>
                    <div style={{ display: 'none' }}>
                        <iframe id="ifr_scm" src="" />
                    </div>
                    <div style={{ display: 'none' }}>
                        <iframe id="ifr_tms" src="" />
                    </div>
                    <div style={{ display: 'none' }}>
                        <iframe id="ifr_cms" src="" />
                    </div>
                </div>

                {/** start 업체검색 팝업 */}
                <OwpDialog
                    title="아이디 선택"
                    disableBackdropClick={false}
                    dialogActions={
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <React.Fragment>
                                <OwpButton
                                    onClick={() => {
                                        console.log(this.state.idItem);
                                    }}
                                    color="secondary"
                                    variant="contained"
                                >
                                    확인
                                </OwpButton>
                                <OwpButton
                                    onClick={() => {
                                        this.owpDialog.handleClose();
                                    }}
                                    color="default"
                                    variant="outlined"
                                >
                                    닫기
                                </OwpButton>
                            </React.Fragment>
                        </div>
                    }
                    innerRef={(ref) => (this.owpDialog = ref)}
                    width="1800px"
                    height="600px"
                >
                    <React.Fragment>
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginBottom: '20px',
                                }}
                            >
                                <table className="table table-striped table-bordered table-advance tmain">
                                    <tbody>{left_list_body}</tbody>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                </OwpDialog>
                {/** end 업체검색 팝업 */}
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            submitLogin: Actions.submitLogin,
        },
        dispatch
    );
}

function mapStateToProps({ auth }) {
    return {
        login: auth.login,
        user: auth.user,
    };
}

export default withStyles(styles, { withTheme: true })(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm))
);
