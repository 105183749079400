import {FuseLoadable} from 'owp/@fuse';

export const C104040Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/pages/C104040',
            component: FuseLoadable({
                loader: () => import('./C104040')
            })
        }
    ]
};
